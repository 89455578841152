import { default as indexVEIWrI2kfrMeta } from "/home/lisov/web/binance-ts/pages/dashboard/index.vue?macro=true";
import { default as page_45scriptfWgynaElbeMeta } from "/home/lisov/web/binance-ts/pages/dashboard/page-script.ts?macro=true";
import { default as indexUW8YyvUFYBMeta } from "/home/lisov/web/binance-ts/pages/index/index.vue?macro=true";
import { default as page_45scriptZWe8z0Et1GMeta } from "/home/lisov/web/binance-ts/pages/index/page-script.ts?macro=true";
export default [
  {
    name: indexVEIWrI2kfrMeta?.name ?? "dashboard",
    path: indexVEIWrI2kfrMeta?.path ?? "/dashboard",
    meta: indexVEIWrI2kfrMeta || {},
    alias: indexVEIWrI2kfrMeta?.alias || [],
    redirect: indexVEIWrI2kfrMeta?.redirect || undefined,
    component: () => import("/home/lisov/web/binance-ts/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: page_45scriptfWgynaElbeMeta?.name ?? "dashboard-page-script",
    path: page_45scriptfWgynaElbeMeta?.path ?? "/dashboard/page-script",
    meta: page_45scriptfWgynaElbeMeta || {},
    alias: page_45scriptfWgynaElbeMeta?.alias || [],
    redirect: page_45scriptfWgynaElbeMeta?.redirect || undefined,
    component: () => import("/home/lisov/web/binance-ts/pages/dashboard/page-script.ts").then(m => m.default || m)
  },
  {
    name: indexUW8YyvUFYBMeta?.name ?? "index",
    path: indexUW8YyvUFYBMeta?.path ?? "/",
    meta: indexUW8YyvUFYBMeta || {},
    alias: indexUW8YyvUFYBMeta?.alias || [],
    redirect: indexUW8YyvUFYBMeta?.redirect || undefined,
    component: () => import("/home/lisov/web/binance-ts/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: page_45scriptZWe8z0Et1GMeta?.name ?? "index-page-script",
    path: page_45scriptZWe8z0Et1GMeta?.path ?? "//page-script",
    meta: page_45scriptZWe8z0Et1GMeta || {},
    alias: page_45scriptZWe8z0Et1GMeta?.alias || [],
    redirect: page_45scriptZWe8z0Et1GMeta?.redirect || undefined,
    component: () => import("/home/lisov/web/binance-ts/pages/index/page-script.ts").then(m => m.default || m)
  }
]